import React,{useState} from 'react';
import {Container,Row,Col} from 'reactstrap';
import {AccordionCard} from '../elements';
import kit_bg from '../../images/sections/kit/title_bg.svg';
import '../../style/questions.css';

const bg_title = {
  backgroundImage:`url(${kit_bg})`,
  backgroundRepeat:'no-repeat',
  backgroundPosition:'center',
  backgroundSize:'30vw'
}

const answers = [
  {
    title:'¿Cuánto cuesta la sesión de cabinas de bronceado? ',
    text:<p className="multiline">
    Los precios para las cabinas de bronceado <span role="img" aria-label="emoji face with starry eyes">🤩</span>
      <br/>
     1 SESIÓN $300
        <br/>
      5 SESIONES $1,200
          <br/>
    10 SESIONES $2,250 Si deseas incluir piernas serían $200 extra
        <br/>
    2 MESES ILIMITADO $2,925 (INCLUYE PIERNAS)
        <br/>
    PAQUETES PIERNAS
        1 SESIÓN $225
        5 SESIONES $900
        10 SESIONES $1,650
    </p>
  },
  {
    title:'¿Cuánto dura la sesión de bronceado?',
    text:`El tiempo de tu bronceado dependerá de tu tipo de piel y de los cuidados que le des, la primer sesión puede ir de 3 a 5 minutos si no te has expuesto al sol.

Horario
Lunes a viernes 8:00–21:00, sábado y domingo	9:00–15:00.`
  },
  {
    title:'¿Tenemos bronceado de aerógrafo?',
    text:`No contamos con ese tipo de servicio.`
  },
  {
    title:'¿Cuál es la diferencia entre la cabina horizontal y la vertical?',
    text:`La cabina vertical cuenta con focos de baja presión y es ideal para cuando empiezas a broncearte ya que como no hay contacto con los acrílicos se obtiene una base más uniforme. Sobre todo en los costados.
Las camas broncean mejor hombros/pecho y piernas.
`
  },
  {
    title:'¿En cuántas sesiones veo los cambios en mi piel?',
    text:`A partir de la primera visita se ven resultados, si iniciamos de cero (sin base de bronceado) la sesión durará pocos minutos para hacerlo de manera gradual y saludable. ¿Puedo usar mi bronceador de la playa en la cabina de bronceado?
Debes usar bronceadores especiales para las camas, no contienen factor de protección solar (FPS) ya que el tiempo de exposición es muy corto, de esa forma obtenemos un bronceado gradual y sin dañar la piel.
`
  },
  {
    title:'¿Qué tan necesario es el uso de bronceador? ',
    text:`La función del bronceador es preparar e hidratar la piel, los rayos UV pueden deshidratarla. Estos brindan un tono dorado y uniforme en un lapso más corto que sin utilizarlo.`
  },
  {
    title:'¿Cuánto tiempo debo esperar entre cada sesión?',
    text:`La frecuencia depende de tu objetivo, recomendamos que esperes un mínimo de 24/48 horas.
`
  },
  {
    title:'¿Pueden los Rayos UV dañar algún órgano interno del cuerpo?',
    text:`No. El máximo que traspasan los rayos son 2 mm, POR LO QUE SERIA IMPOSIBLE QUE UN ORGANO interno SE DAÑARA. `
  },
  {
    title:'¿Cuánto tiempo durará mi bronceado?',
    text:`Te recomendamos que te mantengas hidratado tomando agua y aplicando crema diariamente; si mantienes estos cuidados el bronceado dura de 1 mes a 2.`
  }
]

function Questions(){
  const [selQuest,setSelQuestion] = useState(answers[0])
  const toggle=(quest)=>{
    if(quest.title===selQuest.title){
      setSelQuestion({})
    } else {
      setSelQuestion(quest)
    }

  }
  return(
    <Container className="py-5" fluid>
      <Container>
        <Row className="justify-content-center align-items-center text-center">
          <Col xs={8} className="my-5">
          <p>
          Contamos con lo último en camas y cabinas de bronceado para garantizar uno de larga duración. El tiempo de tu bronceado dependerá de tu tipo de piel y de los cuidados que le des.
          Tenemos cabinas de pie ideales para tus primeras veces, ya que los rayos podrán abarcar más partes de tu piel.
          También tenemos una Leg Tanner. Si solo deseas tener piernas bronceadas, esta es una súper opción. <span role="img" aria-label="emoji face wink">😉</span>
          </p>
            <h1 style={bg_title} className="title_font main_font_size">
                Preguntas
            </h1>
            <p>
 ¿Tienes dudas sobre el bronceado? Consulta nuestras preguntas frecuentes o contáctanos, será un gusto resolver tus dudas.
            </p>

          </Col>
        </Row>
      </Container>
        <Row className="text-center justify-content-around text-left my-3">
          <Container>
            {answers.map(({title,text},i)=>  <AccordionCard toggle={toggle} text={text} title={title} isOpen={selQuest.title===title} key={i}/>)}
          </Container>

        </Row>
    </Container>
  )
}

export default Questions
