import React, { useState, useEffect } from 'react';
import {motion} from 'framer-motion';
import { Link } from "gatsby";
import {
  Container,
  Row,
  Col,
  // Collapse,
  Navbar,
  // NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem
} from 'reactstrap';
import {debounce} from '../../utils'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import logo from '../../images/logo/logo.svg';
import logo_h from '../../images/logo/logo_h.svg';
import '../../style/navb.css'

function Navb(){
  const [isBelow, setBelow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const checkOffset=debounce(()=>{
      if(window.scrollY>10){
      setBelow(true)
    } else {
      setBelow(false)
    }
  },100)
  useEffect(()=>{
    window.addEventListener('scroll',checkOffset)
    return ()=>window.removeEventListener('scroll',checkOffset)
    //eslint-disable-next-line
  },[])
  const toggle = () => setIsOpen(!isOpen);
  return(
    <>
    <Navbar className={`py-0 nav_bar ${isBelow?'nav_below':''}`} fixed='top' expand={true}>
       {/* <div className={`${isBelow?'below':''} banner`}>
         <img width="100px" className="logo_banner img-fluid" src={logo} alt=""/>
       </div> */}
       <NavbarBrand className="py-0" to="/" tag={Link}>
          <img className={`main_logo p-3 ${isBelow?'below':''}`} src={logo} alt=""/>
       </NavbarBrand>
       <Nav className="ml-auto" navbar>
          <NavItem className={`${isBelow?'d-block':'d-none'}`}>
            <NavLink href="https://www.facebook.com/Taninnbylimadecoco" className="text-dark">
              <FontAwesomeIcon size="lg" icon={['fab','facebook-square']}/>
              &nbsp;

            </NavLink>
          </NavItem>
          <NavItem className={`${isBelow?'d-block':'d-none'}`}>
            <NavLink href="https://www.instagram.com/taninnbylimadecoco/" className="text-dark">
              <FontAwesomeIcon size="lg" icon={['fab','instagram']}/>
              &nbsp;

            </NavLink>
          </NavItem>
          <NavItem className="pt-1">
            <NavLink tag={'button'} onClick={toggle} className="menu_togg_round d-flex justify-content-center align-items-center">
              <motion.div animate={isOpen?'openM':'closeM'} variants={{
                openM: { rotate:0 },
                closeM: { rotate:90 }
              }}>
                <FontAwesomeIcon size="lg" icon="ellipsis-v"/>
              </motion.div>
            </NavLink>
          </NavItem>
       </Nav>

     </Navbar>
     <Menu isOpen={isOpen} toggle={toggle}/>
    </>
  )
}

function Menu({isOpen}){
  return(
    <Container className={`slideout ${isOpen&&'slidein'} d-flex flex-column justify-content-around align-items-start`} >
      <Nav vertical>
        <NavItem>
          <NavLink className="side_menu_link text-dark" href="/#radiant">
            Radiant
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="side_menu_link text-dark" href="/#kit">
            Kit
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="side_menu_link text-dark" href="/#location">
            Ubicación
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="side_menu_link text-dark" href="/#social">
            #ig
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="side_menu_link text-dark" href="/#FAQ">
            Preguntas
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} className="side_menu_link text-dark" to="/brand-guides/">
            Brand Guides
          </NavLink>
        </NavItem>
      </Nav>
      <Row>
        <Col>
          <img className="img-fluid" width="100%" src={logo_h} alt=""/>
          <hr />
          <a className="mr-2" href="https://www.facebook.com/Taninnbylimadecoco">
            <FontAwesomeIcon size="lg"
              className="text-dark" icon={['fab','facebook-square']}/>
          </a>
          &nbsp;
          <a href="https://www.instagram.com/taninnbylimadecoco/">
            <FontAwesomeIcon size="lg"
              className="text-dark" icon={['fab','instagram']}/>
          </a>
        </Col>
      </Row>

    </Container>
  )
}

export default Navb
