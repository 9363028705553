export const TOKEN = 'taninn_token'
export const API_URL = process.env.NODE_ENV==='production'
?'https://taninn-back.herokuapp.com/api/v1'
:'http://localhost:3016/api/v1'

export const LOCAL_MESSAGE_TIMEOUT = 5000

export const LOCAL_MESSAGES = {
  nopassword:'El password no puede estar vacío'
}
