import React,{useRef,useEffect,useState,useCallback} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import '../../style/compareimages.css';

function CompareImages({images,altText,className}){
  const overlay = useRef()
  const imgBackG = useRef()
  const [imgW,setImgW] = useState(600)
  const [clicked,setClicked] = useState(false)

  useEffect(()=>{
    initComparisons()
  },[])

  const slideMove = useCallback((e)=>{
    let pos
    const {offsetWidth} = imgBackG.current
    // console.log('clicked',clicked)
    if (clicked){
      pos = getCursorPos(e)
      if(!pos) return false
      if(pos<0) pos = 0
      if(pos>offsetWidth) pos = offsetWidth
      setImgW(pos)
    } else{
      return false
    }

  },[clicked])

  useEffect(()=>{
    window.addEventListener("mousemove", slideMove);
    window.addEventListener("touchmove", slideMove);
    return ()=>{
      window.removeEventListener("mousemove", slideMove)
      window.removeEventListener("touchmove", slideMove)
    }
  },[clicked,slideMove])

  const initComparisons=()=>{
    const {offsetWidth} = overlay.current

    setImgW(offsetWidth/2)
  }

  const slideReady=(e)=>{
    e.preventDefault()
    setClicked(true)
    // window.addEventListener("touchmove", slideMove);
  }
  const slideFinish=(e)=>{
    e.preventDefault()
    setClicked(false)
    // window.removeEventListener("touchmove", slideMove);
  }


  const getCursorPos=(e)=>{
    let a , x = 0;
    if(overlay.current){
      a = overlay.current.getBoundingClientRect()
      x = e.pageX - a.left
      return x - window.pageXOffset
    } else {
      return false
    }


  }

  // console.log('ofw',overlay.current.offsetWidth)
  return(
    <div className={`img_comp ${className}`}>
      <div className="img-comp-container d-flex align-items-center">
          <div className="img-comp-img">
            <img ref={imgBackG} src={images[0]} alt={altText} />
          </div>
          <div onMouseLeave={slideFinish}
               style={{left:`calc(${imgW-150}px)`}}
               className="target-img-comp d-flex justify-content-center align-items-center">
            <div
              onMouseDown={slideReady}
              onMouseUp={slideFinish}
              onTouchStart={slideReady}
              onTouchEnd={slideFinish}
              // onMouseLeave={slideFinish}
              className="pulse img-comp-slider d-flex justify-content-center align-items-center">
              <FontAwesomeIcon className="text-light" icon="arrow-left"/>
              &nbsp;
              <FontAwesomeIcon className="text-light" icon="arrow-right"/>
            </div>
          </div>

          <div ref={overlay} style={{width:`${imgW}px`}} className="img-comp-img img-comp-overlay">
            <img src={images[1]} alt={altText}/>
          </div>
      </div>
    </div>
  )
}

export default CompareImages
