import React from 'react';
import { useStaticQuery,graphql } from 'gatsby';
import { Jumbotron,Container,Row } from 'reactstrap';
import {CompareImages} from '../elements'
import '../../style/jumbo.css'

const Jumbo = (props) => {
  const img = useStaticQuery(graphql`
    query {
      allFile(filter: {relativeDirectory: {eq: "hero"}}) {
        edges {
          node {
            childImageSharp {
              fixed(width: 1920) {
                src
                originalName
              }
            }
          }
        }
      }
    }
    `)
  // console.log(img.allFile.edges[0].node.childImageSharp.fixed.src)
  return (
      <Jumbotron className="jumbo pb-0 mb-0 d-flex justify-content-center align-items-center" fluid>
        <CompareImages className="" images={[img.allFile.edges[0].node.childImageSharp.fixed.src,img.allFile.edges[1].node.childImageSharp.fixed.src]} altText={'image to compare Tan Inn'}/>
          <Container className="jumbo_content" fluid >
              <Row className="flex-column align-items-end justify-content-end cont_50 text-right">
                <h1 className="main_title text-uppercase pr-2">
                  Professional Tanning
                </h1>
                <p className="box_tagline w-sm-50 text-uppercase pr-2">
                  Tu bronceado siempre perfecto
                </p>
              </Row>
          </Container>
      </Jumbotron>
  );
};

export default Jumbo;
