import React from 'react';
import {
  Container,
  Row,
  Col
} from 'reactstrap';

import bg_sections from '../../images/sections/bg_sections.svg';
import logo_h from '../../images/logo/logo_h.svg';

const footerStyle={
  backgroundImage:`linear-gradient(to bottom left, rgba(255,255,255,0),rgba(255,255,255,.9) 60%),url(${bg_sections})`,
  // position:'relative',
  backgroundRepeat:'repeat',
  backgroundPosition:'right'
}

function Footer(){
  return(
    <>
      <Container tag={'footer'} className="cont_50 d-flex flex-column justify-content-center" style={footerStyle} fluid>
        <Row className="justify-content-center my-5">
          <Col xs={6} >
            <img className="img-fluid" src={logo_h} alt=""/>
          </Col>
        </Row>
        <Row className="justify-content-center text-center align-items-center">
          <Col>
            Tan Inn by Lima de Coco <br/>
            Todos los derechos reservados © {new Date().getFullYear()}
          </Col>
        </Row>

      </Container>
      <Container className="bg-dark py-2" fluid>
        <Row>
          <Col className="text-center text-light">
            <a className="text-light" href="https://n12.mx">
            Hecho a mano con <span role="img" aria-label="green heart icon">💚</span>por N12 Estudio</a>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Footer
