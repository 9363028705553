import React from 'react';
import {Container,Row,Col} from 'reactstrap';
import bg_sections from '../../images/sections/bg_sections.svg';

const welStyle={
  backgroundImage:`linear-gradient(to bottom left, rgba(255,255,255,0),rgba(255,255,255,.9) 60%),url(${bg_sections})`,
  position:'relative',
  // backgroundSize:'cover',
  backgroundRepeat:'repeat',
  backgroundPosition:'right'
}

function Welcome(){
  return(
    <Container style={welStyle} fluid>
      <Container>
        <Row className="justify-content-center align-items-center cont_70">
          <Col className="abs_cont d-flex flex-column-reverse align-items-center cont_70" sm={2}>
            <span className="line_section pt-5"/>
            <h4 className="text_rotated text-uppercase yellow_offset text-nowrap">

              Piel perfecta
            </h4>


          </Col>
          <Col sm={10} className="">
            <h1 className="title_font main_font_size">
                The most radiant experience
            </h1>
            <p>
              Bienvenido a <b>Tan Inn by Lima de Coco</b>, un centro de bronceado, con personal altamente capacitado y equipo de última generación, que harán de tu estancia, una experiencia única. Tu piel lucirá radiante desde tu primera visita.
            </p>

          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default Welcome
