import React from 'react';
import {Collapse,Card,CardBody,CardHeader,CardText} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function AccordionCard({isOpen,toggle,title,text}){
  return(
    <Card>
      <CardHeader className="header_quest text-light" onClick={()=>toggle({title,text})}>
        {isOpen
          ?<FontAwesomeIcon icon="chevron-up"/>
          :<FontAwesomeIcon icon="chevron-down"/>
        }
        &nbsp;{title}
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody>
          <CardText className="multiline">
            {text}
          </CardText>
        </CardBody>
      </Collapse>
    </Card>
  )
}

export default AccordionCard
