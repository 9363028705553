/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
// import 'whatwg-fetch';
// import { useStaticQuery, graphql } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import {Navb,Footer} from './sections'
import "./layout.css"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  faArrowLeft,
  faArrowRight,
  faEllipsisV,
  faChevronUp,
  faChevronDown,
  faSpinner,
  faSignOutAlt,
  faPlusCircle,
  faMinusCircle,
  faDownload
} from '@fortawesome/free-solid-svg-icons';

library.add(
  fab,
  faArrowLeft,
  faArrowRight,
  faEllipsisV,
  faChevronUp,
  faChevronDown,
  faSpinner,
  faSignOutAlt,
  faPlusCircle,
  faMinusCircle,
  faDownload
)

const Layout = ({ children }) => {
  return (
    <>
    <Navb/>
      <main>{children}</main>
    <Footer/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
