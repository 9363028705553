import React,{useEffect,useState} from 'react';
import {Container,Row,Col,CardGroup,Card,CardImg} from 'reactstrap';
import {fetchStatusHTML,getErrors} from '../../utils';
import kit_bg from '../../images/sections/kit/title_bg.svg';
import social_bg from '../../images/sections/social/social_bg.svg';

const bg_title = {
  backgroundImage:`url(${kit_bg})`,
  backgroundRepeat:'no-repeat',
  backgroundPosition:'center',
  backgroundSize:'60vw'
}

const bg_social = {
  backgroundImage:`url(${social_bg})`,
  backgroundRepeat:'repeat',
  backgroundPosition:'center',
  backgroundSize:'40vw'
}



function Social(){
  const [images,setImages] = useState([[]])
  const getFeed = async()=>{
    try{
      const response = await fetch(`https://www.instagram.com/taninnbylimadecoco/`)
      .then(fetchStatusHTML)
      const userData = response.match(
          /<script type="text\/javascript">window\._sharedData = (.*)<\/script>/,
        )[1]
        .slice(0, -1);
      const feedData = JSON.parse(userData)
      // console.log('datauser',feedData)
      // .entry_data.ProfilePage[0]
      // .graphql.user)
      const images = feedData
      .entry_data.ProfilePage[0]
      .graphql.user
      .edge_owner_to_timeline_media.edges
      .reduce((acc,curr)=>acc.concat({src:curr.node.display_url,shortcode:curr.node.shortcode}),[])
      let imagesGr = []
      images.map((el,i)=>{
        if(i%3===0){
          imagesGr = imagesGr.concat([images.slice(i,i+3)])
        }
        return null
      })
      setImages(imagesGr)

    } catch(error){
      getErrors(error)
      console.warn(error)
    }
  }
  useEffect(()=>{
    getFeed()
    //eslint-disable-next-line
  },[])

  return(
    <>
    <Container className="py-5">
      <Row className="justify-content-center align-items-center text-center mb-5">
        <Col xs={10} className="">
          <h1 style={bg_title} className="title_font main_font_size">
              #Tanninnbylimadecoco
          </h1>
          <p>
            En Tan Inn, nuestra prioridad es tu piel, por eso te recordamos que no olvides estos tres básicos, mismos que podrás adquirir con nosotros, para que tu experiencia de bronceado sea la mejor.
          </p>

        </Col>
      </Row>
    </Container>
    <Container style={bg_social} fluid>
      <Container>
        <Row className="cont_50">
          {images.map((group,o)=><CardGroup key={o}>
            {group.map((el,i)=><Card tag={'a'}
              rel="noopener noreferrer"
              className="m-1"
              target="_blank"
              href={`https://www.instagram.com/p/${el.shortcode}`} key={i}>
              <CardImg href="" src={el.src}/></Card>)}}
          </CardGroup>  )}

        </Row>
      </Container>
  </Container>
    </>


  )
}

export default Social
