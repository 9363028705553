import React,{useState} from 'react';
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import location_bg from '../../images/sections/location/title_bg.svg';

const bg_title = {
  backgroundImage:`url(${location_bg})`,
  backgroundRepeat:'no-repeat',
  backgroundPosition:'center',
  backgroundSize:'30vw'
}


const sections = [
  {
    title:'Matriz Sonata',
    address:`Plaza Jazz N2 L8 Distrito Sonata, Lomas de Angelópolis II
    San Andrés Cholula, Puebla.`,
    schedule:`Lun a Vie: 8:00 am a 9:00 pm
    Sáb y Dom: 9:00 am a 3:00 pm`,
    telephone:`(222) 210 31 69`,
    location:()=>(<div className="embed-responsive embed-responsive-16by9">
        <iframe
          className="embed-responsive-item"
          title="tan inn sonata"
          width="600"
          height="450"
          frameBorder="0"
          src="https://www.google.com/maps/embed/v1/place?q=tan%20inn%20sonata&key=AIzaSyCPVYghluk8MCYb6oaIAqlrx-c8MfsS5qY"
          allowFullScreen></iframe>)
    </div>)},
  {
    title:'Sucursal Las torres',
    address:`Blvd municipio Libre #1972 Int 10 Ex-Hacienda Mayorazgo
    Puebla Puebla CP 72450`,
    schedule:`Lun a Vie: 8:00 am a 9:00 pm
    Sáb y Dom: 9:00 am a 3:00 pm`,
    telephone:`(222) 227 34 96`,
    location:()=>(<div className="embed-responsive embed-responsive-16by9">
        <iframe
          className="embed-responsive-item"
          title="tan inn las torres"
          width="600"
          height="450"
          frameBorder="0"
          src="https://www.google.com/maps/embed/v1/place?q=Plaza%20Pabellon%20las%20torres&key=AIzaSyCPVYghluk8MCYb6oaIAqlrx-c8MfsS5qY"
          allowFullScreen></iframe>)
    </div>)
  }
]


function Location(){
  const [selSection,setSelSection] = useState(sections[0])
  return(
    <Container className="my-5">
      <Row className="justify-content-center align-items-center text-center my-5">
        <Col>
          <h1 style={bg_title} className="title_font main_font_size mb-5">
              Ubicación
          </h1>
        </Col>
      </Row>
      <Row>
          <Col>
            <Nav justified tabs>
              {sections.map(({title,text,icon,...rest},i)=>
                <NavItem onClick={()=>setSelSection({title,text,icon,...rest})} key={i}>
                  <NavLink href="#" active={selSection.title===title} className="link_nav_us">{title}</NavLink>
                </NavItem>)}
            </Nav>
          </Col>
        </Row>
      <Row className="justify-content-between">
        <Col className="cont_50" xs={12} sm={6}>
          {selSection.location()}
        </Col>
        <Col className="ml-2" xs={12} sm={5}>
            <Row>
              <h3>
                {selSection.title}
              </h3>
            </Row>
            <Row>
              <p className="multiline">{selSection.address}</p>
            </Row>
            <Row>
              <h3>
                Horario
              </h3>
            </Row>
            <Row>
              <p className="multiline">{selSection.schedule}</p>
            </Row>
            <Row>
              <h3>
                Teléfono
              </h3>
            </Row>
            <Row>
              <a href={`callto:${selSection.telephone}`}>{selSection.telephone}</a>
            </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default Location
