import React from 'react';
import {Container,Row,Col} from 'reactstrap';
import bg_sections from '../../images/sections/bg_sections.svg';
import kit_bg from '../../images/sections/kit/title_bg.svg';

import icon_1 from '../../images/sections/kit/icon_1.svg';
import icon_2 from '../../images/sections/kit/icon_2.svg';
import icon_3 from '../../images/sections/kit/icon_3.svg';

const welStyle={
  backgroundImage:`linear-gradient(to bottom left, rgba(255,255,255,0),rgba(255,255,255,.9) 60%),url(${bg_sections})`,
  position:'relative',
  // backgroundSize:'cover',
  backgroundRepeat:'repeat',
  backgroundPosition:'right'
}

const bg_title = {
  backgroundImage:`url(${kit_bg})`,
  backgroundRepeat:'no-repeat',
  backgroundPosition:'center',
  backgroundSize:'30vw'
}

function Kit(){
  return(
    <Container style={welStyle} className="cont_70" fluid>
      <Container>
        <Row className="justify-content-center align-items-center text-center pt-5 mb-5">
          <Col xs={8} className="">
            <h1 style={bg_title} className="title_font main_font_size">
                Kit
            </h1>
            <p>
              En Tan Inn, nuestra prioridad es tu piel, por eso te recordamos que no olvides estos tres básicos, mismos que podrás adquirir con nosotros, para que tu experiencia de bronceado sea la mejor.
            </p>

          </Col>
        </Row>
        <Row className="text-center justify-content-around">
          <Col xs={12} sm={3} className="">
            <img src={icon_1} className="img-fluid mb-4" alt=""/>
            <p>
              Usa unos sunnies especiales para que los rayos UV no afecten tu vista.
            </p>
          </Col>
          <Col xs={12} sm={3} className="">
            <img src={icon_2} className="img-fluid mb-4" alt=""/>
            <p>Utiliza un bronceador especial para camas de bronceado.</p>
          </Col>
          <Col xs={12} sm={3} className="">
            <img src={icon_3} className="img-fluid mb-4" alt=""/>
            <p>Recuerda tomar mucha agua tanto antes, como después de tu sesión de bronceado.</p>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default Kit
