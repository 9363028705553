import React,{useState,useRef,useEffect} from 'react';
import {motion,AnimatePresence} from 'framer-motion';
import {useStaticQuery,graphql} from 'gatsby';
import Img from 'gatsby-image';
import {Container,Row,Col,Card,CardImg} from 'reactstrap';
import {debounce} from '../../utils'
import '../../style/equipment.css'



function Equipment(){
  const lineRef = useRef()
  const pressRef = useRef()
  const cont = useRef()
  const img = useStaticQuery(graphql`
    query machines {
      full:allFile(filter: {relativeDirectory: {eq: "sections/services/machines"}}) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth:900){
                src
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      thumbs:allFile(filter: {relativeDirectory: {eq: "sections/services/machines/thumbs"}}) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth:300){
                src
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      file(name: {eq: "bg_machine"}) {
        childImageSharp {
          fluid {
            src
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  // reductor para asignar a cada nombre de archivo su miniatura y su archivo
  const machines = img.full.edges.reduce((acc,el)=>{
    // Constante para asignar el nombre del archivo
    const nameL = el.node.childImageSharp.fluid.originalName
    // const thumb = img.thumbs.edges.find((thumb)=>console.log('thumb',thumb.node.childImageSharp.fluid.originalName))
    // encontrar el archivo miniatura de entre las miniaturas el archivo que coincida en nombre
    const thumb = img.thumbs.edges.find((thumb)=>thumb.node.childImageSharp.fluid.originalName===nameL)
    // generar un objeto en donde la llave coincide con el nombre del archivo y tiene asignado un  objeto con llave y miniatura
    acc[nameL] = {
      image:el.node.childImageSharp.fluid,
      thumb:thumb.node.childImageSharp.fluid
    }
    return acc
  },{})
  const machOptions = [
    {
      image: machines['essence_56.png'].image,
      thumb: machines['essence_56.png'].thumb,
      name: `ESSENCE 56`,
      perc: 0.7,
      percNote: '8 min. máximo',
      press: 6,
      pressNote: 'Alta cuerpo completo',
      description: `Rayos UV/Traspasa 0.5 milímetros de piel.

      Este tipo de rayos segrega la melanina. Es decir, saca color.(Ideal para crear una base de bronceado).`
    }, {
      image: machines['leg_tanner.png'].image,
      thumb: machines['leg_tanner.png'].thumb,
      name: `LEGTANNER`,
      perc: 1,
      percNote: '12 min. máximo',
      press: 3,
      pressNote: 'Para piernas',
      description: `Rayos UV/Traspasa 0.5 milimetros de piel.
      SOLO PARA PIERNAS.
      Este tipo de rayos segrega la melanina.Es decir, saca color.(Idealpara mantener/crear tu base de bronceado)`
    }, {
      image: machines['open_sun.png'].image,
      thumb: machines['open_sun.png'].thumb,
      name: `OPENSUN 1050`,
      perc: 1,
      percNote: '12 min. máximo',
      press: 6,
      pressNote: 'Baja cuerpo completo',
      description: `Rayos UVA Traspasa 2 milímetros de piel
      Esta cama tiene como función subir de 2 a 4 tonos de broncedo ya que los rayos UVA oxidan la melanina.
      Pueden apagarse los focos de la cara. Ideal para mantener el bronceado.
      (Cada Semana)`
    }, {
      image: machines['sunrise_480.png'].image,
      thumb: machines['sunrise_480.png'].thumb,
      name: `SUNRISE 480`,
      perc: 0.9,
      percNote: '10 min. máximo',
      press: 6,
      pressNote: 'Alta cara y hombros',
      press_add:3,
      press_addNote:'Baja resto del cuerpo',    
      description:`Rayos UVB/UVA Traspasa 0.5 - 2 milímetros de piel
      Esta máquina combina dos tipos de rayos para dar más intensidad en hombros y/o cara, pueden apagarse los focos de la cara.`,
    }, {
      image: machines['sun_select.png'].image,
      thumb: machines['sun_select.png'].thumb,
      name: `SUNSELECT`,
      perc: .8,
      percNote: '9 min. máximo',
      press: 6,
      pressNote: 'Alta cara y hombros',
      press_add:3,
      press_addNote:'Baja resto del cuerpo',
      description: `Rayos UVB/Traspasa 0.5 milímetros de piel.
      Este tipo de rayos segrega la melanina.Es decir, saca color.(Ideal para crear una base de bronceado)`
    }, {
      image: machines['uwe_lotus.png'].image,
      thumb: machines['uwe_lotus.png'].thumb,
      name: `LOTUS`,
      perc: 1,
      percNote: '12 min. máximo',
      press: 5,
      pressNote: 'Hombros y/o Cara',
      description: `Rayos UV/Traspasa 0.5 milímetros de piel.
      Este tipo de rayos segrega la melanina.Es decir, saca color.(Ideal para mantener tu base de bronceado)
      Pueden apagarse los focos de la cara.
      Rayos UVB/UVA Traspasa 0.5-2 milímetros de piel.
      Esta máquina combina dos tipos de rayos para dar más intensidad en hombros y/o cara, pueden apagarse los focos de la cara`
    }

  ]
  const pressInd = new Array(6).fill(null)
  const [lineLength,setLineLength] = useState(0)
  const [percLength,setPercLength] = useState(0)
  const [pressSize,setPressSize] = useState(0)
  const [isVisible,setVisible] = useState(0)
  const [currMach,setCurrMach] = useState(machOptions[0])
  useEffect(()=>{
    if(lineRef.current){
      setLineLength(lineRef.current.getTotalLength())
      setPercLength(lineRef.current.getTotalLength()*(1-currMach.perc))
    }
    if(pressRef.current){
      setPressSize(pressRef.current.getBoundingClientRect().width)
    }
    window.addEventListener('scroll',checkScroll)
    return ()=>window.removeEventListener('scroll',checkScroll)
  //eslint-disable-next-line
  },[])
  useEffect(()=>{
    if(lineRef.current){
      setLineLength(lineRef.current.getTotalLength())
      setPercLength(lineRef.current.getTotalLength()*(1-currMach.perc))
    }
    if(pressRef.current){
      setPressSize(pressRef.current.getBoundingClientRect().width)
    }
  },[currMach])
  const checkScroll=debounce(()=>{
    const place = cont.current.getBoundingClientRect()
    if(place){
      if(place.top>=0&&place.bottom<=(window.innerHeight)){
        setVisible(true)
      } else {
        setVisible(false)
      }
    }
  },400)
  const setMachine=(index)=>{
    setCurrMach(machOptions[index])
  }
  const styleMeter ={
    strokeDasharray:lineLength,
    strokeDashoffset:percLength,
  }
  return (
    <Container className="abs_cont">
      <div className="bg_machine">
        <Img className="img-fluid" alt="backgound image machinery" fluid={img.file.childImageSharp.fluid}/>
      </div>
      <Row>
        <Col className="d-block d-sm-none" xs={2}>
          <div ref={cont} className="sideMenu_machines sticky-top">
            <AnimatePresence>
              {isVisible&&machOptions.map((el,i)=><motion.div
                onClick={()=>setMachine(i)}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: {delay: i * 0.05}}}
                exit={{ opacity: 0 }}
                positionTransition
                key={i}>
                <Card className={`card_machine ${machOptions[i].name===currMach.name&&'selected'}`}>
                  <CardImg  className="fluid-img" tag={Img} fluid={el.thumb}/>
                </Card>
              </motion.div>)}
            </AnimatePresence>

          </div>
        </Col>
        <Col>
          <Row className="justify-content-center">

            <Col className="cont_50 d-flex justify-content-center align-items-end" xs={12} sm={5}>

              <AnimatePresence>
                <motion.div
                  className="img-fluid img_machine"
                  key={currMach.name}
                  initial={{ x:5,opacity: 0 }}
                  animate={{  x:0,opacity: 1 }}
                  exit={{ x:-5,opacity: 0 }}
                  >
                    <Img fluid={currMach.image} className="img-fluid"/>
                  </motion.div>
                </AnimatePresence>
              </Col>
              {/* <Img fluid={currMach.image}/> */}
              <Col xs={12} sm={5}>
                <h2 className="text_sec_color title_font display-3 text-center">
                  Equipos
                </h2>
                <h3 className="subtitle_prod text-center">
                  <b>{currMach.name}</b>
                </h3>
                <hr className="sect_div"/>
                <Row>
                  <Col xs={3}>
                    <h4>
                      Tiempo
                    </h4>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <div className="w-75">
                      <div className="time_indicator">
                        <svg xmlns = "http://www.w3.org/2000/svg" viewBox = "0 0 225 14" >
                        <defs>
                          <linearGradient id="linear-gradient" y1="7" x2="225" y2="7" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stopColor="#ed1d7a"/>
                            <stop offset="1" stopColor="#ad5ea5"/>
                          </linearGradient>
                        </defs>
                        <title>meter</title>
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Layer_1-2" data-name="Layer 1">
                            <line ref={lineRef} className="meter meter_grey" x1="7" y1="7" x2="218" y2="7"/>
                            <line style={styleMeter} className="meter color_meter" x1="7" y1="7" x2="218" y2="7"/>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <small className="text-right mt-1">
                      {currMach.percNote}
                    </small>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={3}>
                  <h4>
                    Presión
                  </h4>
                </Col>
                <Col className={`d-flex justify-content-end`}>
                  <div>
                    <div ref={pressRef} className="pressure_indicator d-flex justify-content-between">
                      {pressInd.map((el,i)=><span key={i} style={{
                        backgroundSize:pressSize,
                        backgroundPosition:`${i*20}% 0`
                      }} className={`${currMach.press>=i&&'colored'} press_gray`}></span>)}
                    </div>
                    <div className="text-right mt-1">
                      <small>
                        {currMach.pressNote}
                      </small>
                    </div>
                  </div>
                  <motion.div
                    animate={currMach.press_add?'show':'hide'}
                    variants={{
                      show:{opacity:1,y:0},
                      hide:{opacity:0,y:5}
                    }}
                    className="ml-2">
                    <div className="pressure_indicator d-flex justify-content-between">
                      {pressInd.map((el,i)=><span key={i} style={{
                        backgroundSize:pressSize,
                        backgroundPosition:`${i*20}% 0`
                      }} className={`${currMach.press_add>=i&&'colored'} press_gray`}></span>)}
                    </div>
                    <div className="text-right mt-1">
                      <small>
                        {currMach.press_addNote&&currMach.press_addNote}
                      </small>
                    </div>
                  </motion.div>





              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <p className="multiline cont_25">
                  {currMach.description}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="py-5 d-none d-sm-flex">
          <div ref={cont}/>
          <AnimatePresence >
            {isVisible&&machOptions.map((el,i)=><motion.div
              onClick={()=>setMachine(i)}
              className="col-4 col-sm-2 mb-3"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: {delay: i * 0.05}}}
              exit={{ opacity: 0 }}
              positionTransition
              key={i}>
              <Card className={`card_machine shadow ${machOptions[i].name===currMach.name&&'selected'}`}>
                <CardImg  className="fluid-img" tag={Img} fluid={el.thumb}/>
              </Card>
            </motion.div>)}
          </AnimatePresence>

        </Row>
        </Col>
      </Row>
    </Container>
  )
}

// function useDebounce(cb,delay,deps=[]){
//   console.log('debouncing')
//   const firstUpdate = useRef(true)
//   useEffect(()=>{
//     if (firstUpdate.current) {
//       firstUpdate.current = false;
//       return;
//     }
//     const handler = setTimeout(() => {
//         cb();
//
//     }, delay);
//
//     return () => {
//         clearTimeout(handler);
//     };
//   //eslint-disable-next-line
//   },[...deps])
// }

export default Equipment
